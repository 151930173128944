<template>
  <div class="container report-container">
    <div v-if="loading" class="text-center my-3">
      <i class="fas fa-spinner fa-spin fs-3"></i> Loading...
    </div>

    <div v-if="!showResults">
      <!-- Search Form -->
      <div class="row mt-3">
        <div class="col-12 mb-3">
          <form @submit.prevent="fetchReport">
            <div class="col text-center">
              <b class="secondary-text">Generate Report</b>
              <hr />
            </div>
            <div class="row justify-content-center align-items-end">
              <div class="col-3">
                <div class="form-group">
                  <label for="reportType">Report Type:</label>
                  <select
                    class="form-select w-100 p-2"
                    id="reportType"
                    v-model="reportType"
                    @change="onReportTypeChange"
                  >
                    <option value="yearly">Yearly</option>
                    <option value="quarterly">Quarterly</option>
                  </select>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="year">Year:</label>
                  <select
                    class="form-select w-100 p-2"
                    id="year"
                    v-model="year"
                  >
                    <option v-for="y in availableYears" :key="y" :value="y">{{ y }}</option>
                  </select>
                </div>
              </div>
              <div class="col-3" v-if="reportType === 'quarterly'">
                <div class="form-group">
                  <label for="quarter">Quarter:</label>
                  <select
                    class="form-select w-100 p-2"
                    id="quarter"
                    v-model="quarter"
                  >
                    <option v-for="q in availableQuarters" :key="q" :value="q">{{ q }}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-center w-100 mt-4">
                <button type="submit" class="button-primary">Search</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="showResults">
      <div class="col text-center">
        <b class="secondary-text">
          Financial Report of
          <span v-if="reportType === 'yearly'">Year {{ year }}</span>
          <span v-if="reportType === 'quarterly'">Quarter {{ quarter }} of {{ year }}</span>
        </b>
        <hr />
      </div>

      <!-- Action Buttons -->
      <div class="row justify-content-between align-items-center mb-3">
        <div class="col-auto">
          <button @click="resetSearch" class="btn btn-outline-secondary">
            New Report
          </button>
        </div>
        <div class="col-auto">
          <button @click="exportToPDF" class="button-primary">Generate PDF</button>
        </div>
      </div>

      <!-- Report Tables -->
      <div class="row">
        <!-- Expenses -->
        <div class="col-6 report-section">
          <div class="table-header bg-yellow">
            <h5 class="text-center">Expenses</h5>
          </div>
          <table class="table table-bordered report-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(value, key) in expenses.expenses_report"
                :key="'expense-' + key"
              >
                <td :class="{ 'font-weight-bold': key === 'Total' }">
                  {{ formatKey(key) }}
                </td>
                <td :class="{ 'font-weight-bold': key === 'Total' }">
                  {{ value !== null ? value : '-' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Incomes -->
        <div class="col-6 report-section">
          <div class="table-header bg-yellow">
            <h5 class="text-center">Incomes</h5>
          </div>
          <table class="table table-bordered report-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, key) in incomes" :key="'income-' + key">
                <td>{{ formatKey(key) }}</td>
                <td>{{ value !== null ? value : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Surplus/Deficit Section -->
      <div class="w-100 d-grid p-1 justify-content-center mt-3">
        <div class="border border-dark d-flex rounded p-2 text-center w-auto">
          <h5 class="me-2"    >Net Income : </h5>
          <div class="text-end">
            <h5> {{ netIncome }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "FinancialReport",
  data() {
    return {
      loading: false,
      showResults: false,
      incomes: {},
      expenses: {
        expenses_report: {},
        Total: null,
      },
      netIncome: 0,
      reportType: "quarterly",
      year: new Date().getFullYear(),
      quarter: 1,
      availableYears: [2022, 2023, 2024, 2025],
      availableQuarters: [1, 2, 3, 4],
    };
  },
  methods: {
    async fetchReport() {
      this.loading = true;
      try {
        const endpoint =
          this.reportType === "yearly"
            ? "/dukorane/generate_yearly_report/"
            : "/dukorane/reports/";

        const body =
          this.reportType === "yearly"
            ? { year: this.year }
            : { year: this.year, quarter: this.quarter };

        const response = await api.post(endpoint, body);
        console.log("ressssuu", response)
        const data =
          this.reportType === "yearly"
            ? response.data.yearly_reports
            : response.data.quarterly_reports;

        this.incomes = data.incomes;
        this.expenses.expenses_report = data.expenses.expenses;
        this.expenses.Total = data.expenses.Total;
        this.netIncome = response.data.net_income;
        this.showResults = true;
      } catch (error) {
        console.error("Error fetching report:", error);
      } finally {
        this.loading = false;
      }
    },
    resetSearch() {
      this.showResults = false;
    },
    formatKey(key) {
      return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
    },
    exportToPDF() {
      const doc = new jsPDF();
      // Your PDF generation logic
      doc.save("Financial_Report.pdf");
    },
    onReportTypeChange() {
      if (this.reportType === "yearly") {
        this.quarter = null;
      } else {
        this.quarter = 1;
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}
.bg-yellow {
  background-color: #f36743;
}
.report-section {
  padding: 0 10px;
}
.table-header {
  background-color: #f36743;
  margin-bottom: 5px;
}
</style>
