import './assets/main.css'
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
// import './../node_modules/@fortawesome/fontawesome-free/css/all.css'

import './../node_modules/boxicons/css/boxicons.min.css'

import "./../node_modules/@fontsource/poppins" // Defaults to weight 400
import "./../node_modules/@fontsource/poppins/400.css" // Specify weight
import "./../node_modules/@fontsource/poppins/800.css" // Specify weight


import {createApp} from 'vue'
import {createPinia} from 'pinia'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#app')
