<template>






    <div class="container" style="padding:10px;">
        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/donations/list">List donations </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    ADD DONATION
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
        </div>
        <div class="row justify-content-center" style="width:100%;">

            <div class="col-10 p-5  shadow-lg">

                <div class="row justify-content-center">

                    <div class="col">
                        <div class="text-center"><!-- <img src="./../../public/img/dukorane.png" style="width: 100px;"
                                alt="">-->
                        </div>

                        <form @submit.prevent="handleSubmit" class="" role="form">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="donor">Donor:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text" id="donor" v-model="donor" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="currency">Currency:</label>
                                            <div class="col-sm-9">
                                                <select name="" v-model="currency" class="form-select" id="">
                                                    <option value="bif">Burundian Francs</option>
                                                    <option value="euro">Euros</option>
                                                    <option value="usd">US Dollars</option>

                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="description">Description:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text" id="description"
                                                    v-model="description" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="amount">Amount:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="number" id="amount"
                                                    v-model="amount" />

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div style="margin-top:3em; text-align:center;">
                                <button type="submit" value="SUBMIT" class="button-primary px-5">
                                    DONATE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>





<script>
import api from '@/api/axios';

export default {
    name: 'Donnation',
    data() {
        return {

            donor: '',
            amount: '',
            currency: '',
            description: ''
        };
    },

    // CURRENCY_CHOICES= (
    //     ('usd','US Dollars'),
    //     ('bif', 'Burundian Francs'),
    //     ('euro', 'Euros')
    // )

    methods: {

        async handleSubmit() {
            const data = {

                'donor': this.donor,
                'amount': this.amount,
                'currency': this.currency,
                'description': this.description
                // Your data to be sent
            };
            try {

                const response = await api.post('/dukorane/donations/add/', data, {
                    headers: {
                        'content-Type': 'application/json', // Set default content type
                        accept: 'application/json' // Set default content type
                        //   'Authorization':'Dukorane '
                    }
                });

                this.$router.push('/donations/list'); 
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
    },
};
</script>
<!-- 

<script>
import axios from 'axios'; // Assuming you have Axios installed

export default {
    data() {
        return {

            custom_period_end: '',
            custom_period_start: '',
            address: '',
            joined: '',
            picture: null, // Store selected image file
        };
    },
    methods: {
        async handleSubmit() {


            const formData = {

                'custom_period_end': this.custom_period_end,
                'custom_period_start': this.custom_period_start,
            }



            try {
                const response = await axios.post('/dukorane/membership-fees/create/', formData);

                // Handle successful creation (e.g., display success message, redirect)
                console.log('Member created successfully:', response.data);
            } catch (error) {
                console.error('Error creating member:', error);
                // Handle creation errors (e.g., display error message)
            }
        },
    },
};
</script> -->