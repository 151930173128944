<template>
    <div class="container">

        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/members/add">Add members </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    MEMBER LIST
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
            <!-- <hr> -->
            <div class="col-12">
                <!-- <div class="text-center " v-if="members.response_data.length===0"><i class='bx bxs-like bx-spin secondary-text'></i> Loading ...</div> -->
                <table class="table table-sm table-responsive txt-1">
                    <tr class="primary">



                        <th>First name</th>
                        <th>Last name</th>
                        <th>Church name</th>
                        <th>Address</th>
                        <th>Joined</th>
                        <th></th>
                    </tr>
                    <!-- </thead>   -->
                    <tbody>


                        <tr v-for="member in members.response_data">
                            <td>{{ member.first_name }}</td>
                            <td>{{ member.last_name }}</td>
                            <td>{{ member.church_name }}</td>
                            <td>{{ member.address }}</td>
                            <td>{{ member.joined }}</td>
                            <td>
                                <button @click="getMemberDetail(member.id)" type="button" class="button-primary"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <i class='bx bx-collapse-alt'></i>
                                </button>
                                <button class="button-primary"><i @click="deleteMembers(member.id)"
                                        class='bx bx-trash-alt '></i></button>

                                <button class="button-primary"><i @click="getMemberDetail(member.id)" class='bx bx-pen '
                                        data-bs-toggle="modal" data-bs-target="#example2Modal"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    <!-- Button trigger modal -->

    <!-- detail Modal -->
    <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content ">
                <div class="d-flex justify-content-between align-items-center px-4">
                    <h5 class="modal-title" id="exampleModalLabel">Detail <b class="secondary-text">{{
                            memberProfile.first_name }}</b> </h5>
                            <div>
                            <img src="../../../public/img/dukorane.png" style="width: 75px; margin-left:-100%" alt="">

                            </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <hr class="mx-2 mt-1">
                <div class="modal-body  "  ref="pdfContent">
                    <div class="text-center mb-3">
                        <b>Profile</b>
                    </div>
                    <div v-if="memberProfile">
                        <div align="center" class="my-3"> <img class="rounded-3 border" v-bind:src="memberPicture"
                                width="150" alt="" srcset=""></div>



                        <div class="row justify-content-center">
                            <div class="col-5 text-muted"><i class='bx bxl-slack-old secondary-text '></i> First name:
                            </div>
                            <div class="col-5  "> <b> {{ memberProfile.first_name }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bxl-slack-old secondary-text'></i> Last name:
                            </div>
                            <div class="col-5  "> <b> {{ memberProfile.last_name }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bx-church secondary-text'></i> Church name:</div>
                            <div class="col-5 "> <b> {{ memberProfile.church_name }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bx-map secondary-text'></i> Address:</div>
                            <div class="col-5 "> <b> {{ memberProfile.address }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bx-time-five secondary-text'></i> Joined At:
                            </div>
                            <div class="col-5 "> <b> {{ memberProfile.joined }}</b></div>
                        </div>
                        <hr>
                        <div>

                            <div class="text-center my-3"><b>Loans</b></div>
                            <table v-if="memberLoan && memberLoan.length !== 0" class="table table-sm table-spriped mb-3">

                                <tr class="primary">
                                    <th scope="col">Amount Given</th>
                                    <th scope="col">Amount to be repaid</th>
                                    <th scope="col">Amount left to be repaid</th>
                                    <th></th>
                                </tr>


                                <tbody>
                                    <tr v-for="memberLoan in memberLoan">
                                        <td>{{ memberLoan.amount_given }}</td>
                                        <td>{{ memberLoan.amount_to_be_repaid }}</td>
                                        <td>{{ memberLoan.amount_left_to_be_repaid }}</td>
                                        <td> <button class="button-primary"><i @click="getRepay(memberLoan.id)"
                                                    class='bx bx-pen ' data-bs-toggle="modal"
                                                    data-bs-target="#example3Modal"></i></button></td>
                                    </tr>
                                </tbody>

                            </table>
                            <div v-if="memberLoan && memberLoan.length !== 0" class="text-center text-muted  mb-3">
                                <i class='bx bx-no-entry secondary-text'></i> No loans !
                            </div>


                        </div>

                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
                <button class="button-secondary" @click="exportToPDF">Export to PDF</button>
            </div>
        </div>
    </div>

    <!--update Modal -->
    <div class="modal fade " id="example2Modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content ">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Update <b class="secondary-text">{{
                            memberProfile.first_name }}</b> </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center mb-3">
                        <b>Profile</b>
                    </div>

                    <form @submit.prevent="updateMember" class="" role="form">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group mt-2">
                                    <div class="row">
                                        <label class="col-sm-3 txt-1 my-auto" for="first_name">First name:</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="text" id="first_name"
                                                v-model="first_name" />

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mt-2">
                                    <div class="row">
                                        <label class="col-sm-3 txt-1 my-auto" for="last_name">Last name:</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="text" id="last_name"
                                                v-model="last_name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mt-2">
                                    <div class="row">
                                        <label class="col-sm-3 txt-1 my-auto" for="church_name">Church name:</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="text" id="church_name"
                                                v-model="church_name" />

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mt-2">
                                    <div class="row">
                                        <label class="col-sm-3 txt-1 my-auto" for="phone_number">Phone
                                            number:</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="text" id="phone_number"
                                                v-model="phone_number" />

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mt-2">
                                    <div class="row">
                                        <label class="col-sm-3 txt-1 my-auto" for="address">Address:</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="text" id="address" v-model="address" />

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">

                                <div class="form-group mt-2">
                                    <div class="row">
                                        <label class="col-sm-3 txt-1 my-auto" for="joined">Joined:</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="date" id="joined" v-model="joined" />

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
        <div class="form-group mt-2">
            <div class="row">
                <label class="col-sm-3 txt-1 my-auto" for="picture">Picture:</label>
                <div class="col-sm-9">
                    <!-- Current image preview -->
                    <div class="mb-2" v-if="memberPicture">
                        <img :src="memberPicture" alt="Current profile picture" 
                             class="img-thumbnail" style="max-width: 150px;">
                    </div>
                    <!-- File input -->
                    <input class="form-control" type="file" 
                           id="picture" 
                           ref="imageInput" 
                           @change="handleFileUpload" 
                           accept="image/*" />
                    <small class="text-muted">Leave empty to keep current picture</small>
                </div>
            </div>
        </div>
    </div>
                        </div>
                        <div style="margin-top:3em; text-align:center;">

                            <button type="submit" class="button-primary update px-5" id="update" :disabled="isLoading">
                                    <span v-if="isLoading">Updating member...</span>
                                    <span v-else>Update Member</span>
                                </button>
                        </div>
                    </form>

                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>

                    implement well the delete member, add the modal to adk if he want reaaly delete the member, exampe : Would you really want to delete member_name ?,        add loading while deleting, and close modal on success, also refresh the list on success
                </div> -->
            </div>
        </div>
    </div>

    <!-- repayment -->
    <div class="modal fade " id="example3Modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content ">
                <div class="modal-header">
                    <!-- <h5 class="modal-title" id="exampleModalLabel">Update <b class="secondary-text">{{
                            memberProfile.first_name }}</b> </h5> -->
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center mb-3">
                        <b>REPAY</b>
                    </div>

                    <form @submit.prevent="loansRepayment" class="" role="form">
                        <div class="row">

                            <div class="col-6">
                                <div class="form-group mt-2">
                                    <div class="row">
                                        <label class="col-sm-3 txt-1 my-auto" for="rep_amount">rep_amount:</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="text" id="rep_amount"
                                                v-model="rep_amount" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style="margin-top:3em; text-align:center;">
                            <button type="submit" href="/" value="SUBMIT" class="button-primary px-5">
                                REPAY
                            </button>
                        </div>
                    </form>

                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>
















</template>




<script >
import api from '@/api/axios';
// import html2pdf from 'html2pdf.js';
// import { useRouter } from 'vue-router';
// import { nextTick, ref } from 'vue';;  // If using Composition API

export default {
    name: 'memberList',
    data() {
        return {
            members: {
                response_data:[

                ]
            },
            memberProfile: [],
            memberPicture: '',
            memberLoan: [],
            updateMember_data: [],


            first_name: '',
            last_name: '',
            church_name: '',
            phone_number: '',
            address: '',
            joined: '',
            picture: null,
            currentPicture: '',

            id: null,
            loanId: null,
            success: false,
            error: false,
            rep_amount: 0,
            isLoading : false
        };
    },
    mounted() {
        this.fetchMembers()
        // console.log(' iiiiiiiiiiiiii')
    }
    ,


    methods: {

        handleFileUpload(event) {
        const file = event.target.files[0]; // Get the first selected file
        if (file) {
            this.picture = file; // Store the file in the 'picture' data property
            this.memberPicture = URL.createObjectURL(file);
        }
    },
        async fetchMembers() {

            try {
                const response = await api.get('/dukorane/members/');

                this.members = response.data; // Assuming the response contains a token
                // localStorage.setItem('authToken', token);
                console.log(this.members)

                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
        async getMemberDetail(id) {
            this.id = id

            try {
                const response = await api.get('/dukorane/members/' + id + '/');

                this.memberProfile = response.data['response_data']['member']; // Assuming the response contains a token
                this.memberLoan = response.data['response_data']['loan_data'];
                console.log(this.memberLoan, this.memberProfile,  ' ----')
                // console.log(memberDetail)
                this.memberPicture = response.config.baseURL + this.memberProfile['picture']
                console.log(this.memberPicture)
                this.currentPicture = this.memberProfile['picture'];


                this.first_name = this.memberProfile.first_name;
                this.last_name = this.memberProfile.last_name;
                this.church_name = this.memberProfile.church_name;
                this.phone_number = this.memberProfile.phone_number;
                this.address = this.memberProfile.address;
                this.joined = this.memberProfile.joined;



                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
        async deleteMembers(id) {

            try {
                const response = await api.put('/dukorane/members/delete/' + id + '/', {
                    headers: {
                        'content-Type': 'application/json', // Set default content type
                        accept: 'application/json' // Set default content type
                        //   'Authorization':'Dukorane '
                    }
                });

                const resp = response.data // Assuming the response contains a token
                // localStorage.setItem('authToken', token);
                console.log(resp)
                // this.$router.push('/members/ list');
                window.location.href='/members/list'

                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
        async updateMember() {
            this.isLoading = true;

            // Create FormData to handle file upload
            const formData = new FormData();
            formData.append('first_name', this.first_name);
            formData.append('last_name', this.last_name);
            formData.append('church_name', this.church_name);
            formData.append('phone_number', this.phone_number);
            formData.append('address', this.address);
            formData.append('joined', this.joined);
            formData.append('is_active', true);

            
            // Only append picture if a new one was selected
            if (this.picture) {
                formData.append('picture', this.picture);
            }

            try {
                const response = await api.post('/dukorane/members/update/' + this.id + '/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });

                const resp = response.data;
                console.log(resp);
                this.isLoading = false;

                // Close modal
                const modal = document.getElementById('example2Modal');
                const bootstrapModal = bootstrap.Modal.getInstance(modal);
                if (bootstrapModal) {
                    bootstrapModal.hide();
                }

                // Refresh the list
                await this.fetchMembers();
                
                // Reset form
                this.picture = null;
                if (this.$refs.imageInput) {
                    this.$refs.imageInput.value = '';
                }

            } catch (error) {
                console.error('Update error:', error);
                this.isLoading = false;
            }
        },

        async getRepay(loanId) {
            this.loanId = loanId

            console.log(this.loanId, ' ------------')
        },
        async loansRepayment() {

            const data = {
                'loan': this.loanId,
                'amount': this.rep_amount,

            };
            try {
                const response = await api.post('/dukorane/loans/repayment/' + this.loanId + '/', data, {
                    headers: {
                        'content-Type': 'application/json', // Set default content type
                        accept: 'application/json' // Set default content type
                        //   'Authorization':'Dukorane '
                    }
                });

                const resp = response.data
                // this.$router.push('/members/list');// Assuming the response contains a token
                // localStorage.setItem('authToken', token);
        window.location.href = '/members/list';

                console.log(resp)







                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        }
    //   async  exportToPDF() {
    //         const element = this.$refs.pdfContent;
    //         html2pdf()
    //             .set({
    //                 margin: 10, // Adjust margins as needed
    //                 filename: 'member.pdf',
    //                 image: { // Handle images if necessary
    //                     type: 'png',
    //                     quality: 0.95,
    //                 },
    //             })
    //             .from(element)
    //             .save();
    //     },
    },
};
</script>