<template>






    <div class="container" style="padding:10px;">
        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/transport/list">List transport </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    TRANSPORT
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
        </div>
        <div class="row justify-content-center" style="width:100%;">

            <div class="col-10 p-5  shadow-lg">

                <div class="row justify-content-center">

                    <div class="col">
                        <div class="text-center"><!-- <img src="./../../public/img/dukorane.png" style="width: 100px;"
                                alt="">-->
                        </div>

                        <form @submit.prevent="handleSubmit" class="" role="form">
                            <div class="row">

                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="description">Description:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text" id="description"
                                                    v-model="description" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="amount">Amount:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="number" id="amount"
                                                    v-model="amount" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="date">Date:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="date" id="date" v-model="date" />

                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div style="margin-top:3em; text-align:center;">
                                <button type="submit" value="SUBMIT" class="button-primary px-5">
                                    SUBMIT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>





<script>
import api from '@/api/axios';

export default {
    name: 'Transport add',
    data() {
        return {


            amount: '',
            date: '',
            description: ''
        };
    },

    // CURRENCY_CHOICES= (
    //     ('usd','US Dollars'),
    //     ('bif', 'Burundian Francs'),
    //     ('euro', 'Euros')
    // )

    methods: {

        async handleSubmit() {
            const data = {


                'amount': this.amount,
                'date': this.date,
                'description': this.description

            };
            try {

                const response = await api.post('/dukorane/dukorane-transport/create/', data, {
                    headers: {
                        'content-Type': 'application/json', // Set default content type
                        accept: 'application/json' // Set default content type
                        //   'Authorization':'Dukorane '
                    }
                });
                this.$router.push('/transport/list');

            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
    },
};
</script>
