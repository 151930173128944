<script setup>
import { RouterLink, RouterView } from 'vue-router';
import HelloWorld from './components/HelloWorld.vue';
</script>

<template>
  <div id="app" v-if="isAuth === 'true'">
    <div class="sidebar" :class="{ close: isSidebarClosed }">
      <div class="logo-details">
        <img src="./../public/img/dukorane.png" alt="" width="13">
        <span class="logo_name">Dukorane</span>
      </div>
      <ul class="nav-links">
        <li v-for="item in menuItems" :key="item.name">
          <!-- working on hover-->
          <template v-if="isSidebarClosed">
            <div class="iocn-link">
              <a :href="item.link">
                <i :class="item.icon"></i>
                <span class="link_name">{{ item.name }}</span>
              </a>
              <ul v-if="!item.subMenu" class="sub-menu">
                <li><a :href="item.link">{{ item.name }}</a></li>
              </ul>
              <ul class="sub-menu" >
  <li v-for="subItem in item.subMenu" :key="subItem.name">
    <a :href="subItem.link">{{ subItem.name }}</a>
  </li>
</ul>
            </div>
          </template>

          <template v-else>
            <div class="iocn-link">
  <a :href="item.link">
    <i :class="item.icon"></i>
    <span class="link_name">{{ item.name }}</span>
  </a>
  <!-- Change icon based on submenu state, only show if there are submenus -->
  <i v-if="item.subMenu && item.subMenu.length > 0" 
     :class="isSubMenuOpen(item.name) ? 'bx bxs-chevron-up arrow' : 'bx bxs-chevron-down arrow'" 
     @click="toggleSubMenu(item.name)">
  </i>
</div>

<ul class="sub-menu" v-if="isSubMenuOpen(item.name)">
  <li v-for="subItem in item.subMenu" :key="subItem.name">
    <a :href="subItem.link">{{ subItem.name }}</a>
  </li>
</ul>
</template>


        </li>
      </ul>
    </div>
    <section class="home-section">
      <div class="home-content bg-light shadow">
        <i class='bx bxs-grid-alt' @click="toggleSidebar"></i>
        <div class="d-flex bg-dark">
          <button @click="logout()" class="button-secondary">LOGOUT</button>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <RouterView />
          </div>
        </div>
      </div>
    </section>
  </div>
  <div v-else>
    <RouterView />
  </div>
</template>

<script>
import router from '@/router';

export default {
  data() {
    return {
      isAuth: false,
      isSidebarClosed: true,
      openSubMenus: [],
      menuItems: [
        {
          name: "Dashboard",
          link: "/",
          icon: "bx bx-tachometer",
        },
        {
          name: "Members",
          link: "#",
          icon: "bx bx-group",
          subMenu: [
            { name: "Add Member", link: "/members/add" },
            { name: "List Members", link: "/members/list" },
            { name: "Membership fees", link: "/membership/list" },
          ],
        },
        {
          name: "Loans",
          link: "#",
          icon: "bx bxs-analyse",
          subMenu: [
            { name: "Add loans", link: "/loans/add" },
            { name: "Loans", link: "/loans/list" },
            { name: "List loan administration fees", link: "/loans/administration/fees/list" },
            { name: "Add loan administration fees", link: "/loans/administration/fees/add" },
          ],
        },
        {
          name: "Donations",
          link: "#",
          icon: "bx bx-donate-heart",
          subMenu: [
            { name: "Add donations", link: "/donations/add" },
            { name: "List donations", link: "/donations/list" },
          ],
        },
        {
          name: "Expenses",
          link: "#",
          icon: "bx bxs-offer",
          subMenu: [
            { name: "Add expenses", link: "/expenses/add" },
            { name: "List expenses", link: "/expenses/list" },
          ],
        },
        {
          name: "Transport",
          link: "#",
          icon: "bx bxs-car",
          subMenu: [
            { name: "Add transport", link: "/transport/add" },
            { name: "List transport", link: "/transport/list" },
          ],
        },
        {
          name: "Report",
          link: "#",
          icon: "bx bxs-report",
          subMenu: [
            { name: "Report", link: "/report/" },
          ],
        },
      ],
    };
  },
  created() {
    this.isAuth = localStorage.getItem('isAuth');

    if (this.isAuth === 'true' && window.location.pathname !== '/') {
      console.log('---redirecting to home');
      // window.location.href = '/';
    } else if (this.isAuth === 'false' && window.location.pathname !== '/login') {
      console.log('---redirecting to login');
      window.location.href = '/login';
    }
  },
  methods: {
  logout() {
    localStorage.removeItem('authToken');
    localStorage.setItem('isAuth', 'false'); // Ensure this is a string
    window.location.href = '/login';
  },
  toggleSidebar() {
    this.isSidebarClosed = !this.isSidebarClosed;
  },
  toggleSubMenu(name) {
  if (this.openSubMenus.includes(name)) {
    // If the submenu is already open, remove it from the array
    this.openSubMenus = [];
  } else {
    // If it's not open, close all other submenus and open the selected one
    this.openSubMenus = [name];
  }
},
  isSubMenuOpen(name) {
    return this.openSubMenus.includes(name);
  }
}

};
</script>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;
    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
