<template>
    <div class="container">

        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/membership/add"> + Membership fees  </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    MEMBERSHIP FEES LIST
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
            <!-- <hr> -->
            <div class="col-12">
                <!-- <div class="text-center " v-if="members.response_data.length===0"><i class='bx bxs-like bx-spin secondary-text'></i> Loading ...</div> -->
                <table class="table table-sm table-responsive striped txt-1">
                    <tr class="primary">



                        <th>Amount</th>
                        <th>Member</th>

                        <th>Start period</th>
                        <th>End Period name</th>
                       
                    </tr>
                    <!-- </thead>   -->
                    <tbody>


                        <tr v-for="member in members">
                            <td>{{ member.amount }}</td>
                            <td>{{ member.member_first_name }} {{ member.member_last_name }}</td>

                            <td>{{ member.custom_period_start }}</td>
                            <td>{{ member.custom_period_end }}</td>
<!--                            
                            <td>
                                <button @click="getMemberDetail(member.id)" type="button" class="button-primary"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <i class='bx bx-collapse-alt'></i>
                                </button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content ">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail <b class="secondary-text">#{{
                            memberProfile.first_name }}</b> </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center mb-3">
                        <b>Profile</b>
                    </div>
                    <div v-if="memberProfile">
                        <div align="center" class="my-3"> <img class="rounded-3 border" v-bind:src="memberPicture"
                                width="150" alt="" srcset=""></div>



                        <div class="row justify-content-center">
                            <div class="col-5 text-muted"><i class='bx bxl-slack-old secondary-text '></i> First name:
                            </div>
                            <div class="col-5  "> <b> {{ memberProfile.first_name }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bxl-slack-old secondary-text'></i> Last name:
                            </div>
                            <div class="col-5  "> <b> {{ memberProfile.last_name }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bx-church secondary-text'></i> Church name:</div>
                            <div class="col-5 "> <b> {{ memberProfile.church_name }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bx-map secondary-text'></i> Address:</div>
                            <div class="col-5 "> <b> {{ memberProfile.address }}</b></div>
                            <div class="col-5 text-muted"><i class='bx bx-time-five secondary-text'></i> Joined At:
                            </div>
                            <div class="col-5 "> <b> {{ memberProfile.joined }}</b></div>
                        </div>
                        <hr>
                        <div>

                            <div class="text-center my-3"><b>Loans</b></div>
                            <table v-if="memberLoan.length !== 0" class="table table-sm table-spriped mb-3">

                                <tr class="primary">
                                    <th scope="col">Amount Given</th>
                                    <th scope="col">Amount to be repaid</th>
                                    <th scope="col">Amount left to be repaid</th>
                                </tr>


                                <tbody>
                                    <tr v-for="memberLoan in memberLoan">
                                        <td>{{ memberLoan.amount_given }}</td>
                                        <td>{{ memberLoan.amount_to_be_repaid }}</td>
                                        <td>{{ memberLoan.amount_left_to_be_repaid }}</td>
                                    </tr>
                                </tbody>

                            </table>
                            <div v-if="memberLoan.length === 0" class="text-center text-muted  mb-3">
                                <i class='bx bx-no-entry secondary-text'></i> No loans !
                            </div>


                        </div>

                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>
</template>




<script>
import api from '@/api/axios';

export default {
    name: 'memberList',
    data() {
        return {
            members: [],
            memberProfile: [],
            memberPicture: '',
            memberLoan: []
        };
    },
    mounted() {
        this.fetchMembers()
        // console.log(' iiiiiiiiiiiiii')
    }
    ,


    methods: {
        async fetchMembers() {

            try {
                const response = await api.get('/dukorane/membership-fees/');

                this.members = response.data; // Assuming the response contains a token
                // localStorage.setItem('authToken', token);
                console.log(this.members)

                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
        async getMemberDetail(id) {
            try {
                const response = await api.get('/dukorane/members/' + id + '/');

                this.memberProfile = response.data['response_data']['member']; // Assuming the response contains a token
                this.memberLoan = response.data['response_data']['loan_data'];
                console.log(this.memberLoan, ' ----')
                // console.log(memberDetail)
                this.memberPicture = response.config.baseURL + this.memberProfile['picture']
                console.log(this.memberPicture)
                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        }
    },
};
</script>