<template>






    <div class="container" style="padding:10px;">
        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/expenses/list">List expenses </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    ADD EXPENSES
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
        </div>
        <div class="row justify-content-center" style="width:100%;">

            <div class="col-10 p-5  shadow-lg">

                <div class="row justify-content-center">

                    <div class="col">
                        <div class="text-center"><!-- <img src="./../../public/img/dukorane.png" style="width: 100px;"
                                alt="">-->
                        </div>

                        <form @submit.prevent="handleSubmit" class="" role="form">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="expense_type">Expense
                                                type:</label>
                                            <div class="col-sm-9">
                                                <select class="form-select" id="expense_type" v-model="expense_type">
                                                    <option value="rental_charges">Rental Charges</option>
                                                    <option value="administration">Administration</option>
                                                    <option value="communication">Communication</option>
                                                    <option value="salary">Salary</option>
                                                    <option value="transport">Transport</option>
                                                    <option value="insurance">Insurance</option>
                                                    <option value="workshop">workshop</option>
                                                    <option value="bank_charges">Bank Charges</option>
                                                    <option value="conference">Conferences</option>
                                                    <option value="other">Other</option>
                                                </select>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="service_designation">Service
                                                designation:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text" id="service_designation"
                                                    v-model="service_designation" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="amount">Amount:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="number" id="amount"
                                                    v-model="amount" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="rate">Rate:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text" id="rate" v-model="rate" />

                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div style="margin-top:3em; text-align:center;">
                                <button type="submit" value="SUBMIT" class="button-primary px-5">
                                    SUBMIT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>





<script>
import api from '@/api/axios';

export default {
    name: 'Expenses',
    data() {
        return {

            expense_type: '',
            amount: '',
            service_designation: '',
            rate: ''
        };
    },



    methods: {

        async handleSubmit() {
            const data = {


                'expense_type': this.expense_type,
                'amount': this.amount,
                'service_designation': this.service_designation,
                'rate': this.rate

            };
            try {

                const response = await api.post('/dukorane/expenses/create/', data, {
                    headers: {
                        'content-Type': 'application/json', // Set default content type
                        accept: 'application/json' // Set default content type
                        //   'Authorization':'Dukorane '
                    }
                });
                this.$router.push('/expenses/list');

            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
    },
};
</script>
