<template>
    <div class="container" style="padding: 10px;">
        <div class="row mt-3">
            <div class="col-2">
                <a class="button-primary" href="/members/list">List members</a>
            </div>
            <div class="col text-center">
                <b class="secondary-text">ADD A MEMBER</b>
                <hr>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row justify-content-center" style="width: 100%;">
            <div class="col-10 p-5 shadow-lg">
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="text-center"></div>
                        <form @submit.prevent="handleSubmit" class="" role="form">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="first_name">First name:</label>
                                            <div class="col-sm-9">
                                                <input required class="form-control" type="text" id="first_name"
                                                    v-model="first_name" :disabled="isLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="last_name">Last name:</label>
                                            <div class="col-sm-9">
                                                <input required class="form-control" type="text" id="last_name"
                                                    v-model="last_name" :disabled="isLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="church_name">Church name:</label>
                                            <div class="col-sm-9">
                                                <input required class="form-control" type="text" id="church_name"
                                                    v-model="church_name" :disabled="isLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="phone_number">Phone number:</label>
                                            <div class="col-sm-9">
    <input 
        required 
        class="form-control" 
        type="text" 
        id="phone_number" 
        v-model="phone_number" 
        :disabled="isLoading" 
        v-number 
    />
</div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="address">Address:</label>
                                            <div class="col-sm-9">
                                                <input required class="form-control" type="text" id="address"
                                                    v-model="address" :disabled="isLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="joined">Joined:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" required type="date" id="joined" v-model="joined" :disabled="isLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="picture">Picture:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="file" id="picture" ref="imageInput"
                                                    required :disabled="isLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 3em; text-align: center;">
                                <button type="submit" class="button-primary px-5" :disabled="isLoading">
                                    <span v-if="isLoading">Saving member...</span>
                                    <span v-else>Save Member</span>
                                </button>
                            </div>
                            <div v-if="isLoading" class="text-center mt-3">
                                <p>Please wait, we are processing your request...</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/axios';

export default {
    name: 'MemberAdd',
    data() {
        return {
            first_name: '',
            last_name: '',
            church_name: '',
            phone_number: '',
            address: '',
            joined: '',
            picture: null,
            isLoading: false, // Loading state
        };
    },

    methods: {
        async handleSubmit() {
            this.isLoading = true; // Set loading state to true

            const formData = new FormData(); // Using FormData to handle file uploads
            formData.append('first_name', this.first_name);
            formData.append('last_name', this.last_name);
            formData.append('church_name', this.church_name);
            formData.append('phone_number', this.phone_number);
            formData.append('address', this.address);
            formData.append('joined', this.joined);
            formData.append('picture', this.$refs.imageInput.files[0]); // Append the file

            try {
                const response = await api.post('/dukorane/members/create/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set content type for FormData
                        'Accept': 'application/json' // Set accept header
                    }
                });

                this.$router.push('/members/list');
            } catch (error) {
                console.error('Error while saving member:', error);
                // Handle errors here, such as displaying an error message
            } finally {
                this.isLoading = false; // Reset loading state
            }
        },
    },
};
</script>


