<template>
    <div class="container">

        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/donations/add">ADD DONATION </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    DONATIONS LIST
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
            <!-- <hr> -->
            <div class="col-12">
                <!-- <div class="text-center " v-if="members.response_data.length===0"><i class='bx bxs-like bx-spin secondary-text'></i> Loading ...</div> -->
                <table class="table table-sm table-responsive striped txt-1">
                    <tr class="primary">



                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Donor</th> <th>Description</th>
                        <th>Date</th>

                        <th></th>
                    </tr>
                    <!-- </thead>   -->
                    <tbody>


                        <tr v-for="donation in donations.response_data">
                            <td>{{ donation.amount }}</td>
                            <td>{{ donation.currency }}</td>
                            <td>{{ donation.donor }}</td>
                            <td>{{ donation.description }}</td>
                            <td>{{ donation.date }}</td>

                            <!-- <td>
                                <button @click="getLoanDetail(loan.id)" type="button" class="button-primary"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <i class='bx bx-collapse-alt'></i>
                                </button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content ">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail <b class="secondary-text">Loan</b> </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div>

                        <div class="container-fluid">
                            <div class="row">
                                <div class="col">
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <b class="secondary-text mb-3">Loan</b>
                                            <div class="col-6 text-muted"><i
                                                    class='bx bx-dialpad-alt secondary-text '></i> Amount:
                                            </div>
                                            <div class="col-6  "> <b> {{ loanDtl.amount }}</b></div>
                                            <div class="col-6 text-muted"><i
                                                    class='bx bx-dialpad-alt secondary-text'></i> Loan
                                                Duration:
                                            </div>
                                            <div class="col-6  "> <b> {{ loanDtl.loan_duration }} Year(s)</b></div>
                                            <div class="col-6 text-muted"><i
                                                    class='bx bx-dialpad-alt secondary-text'></i> Is repaid:
                                            </div>
                                            <div class="col-6 "> <b v-if="loanDtl.is_repaid"> Yes</b><b v-else> No</b>
                                            </div>
                                            <div class="col-6 text-muted"><i
                                                    class='bx bx-dialpad-alt secondary-text'></i> Date:</div>
                                            <div class="col-6 "> <b> {{ loanDtl.date }}</b></div>
                                            <div class="col-6 text-muted"><i
                                                    class='bx bx-dialpad-alt secondary-text'></i> Description:</div>
                                            <div class="col-6 "> <b> {{ loanDtl.description }}</b></div>

                                        </div>

                                    </div>
                                </div>
                                <div class="col">
                                    <div class="container-fluid">

                                        <div v-if="memberDtl" class="row justify-content-center">
                                            <b class="secondary-text mb-3">Loan Owner</b>
                                            <div class="col-6 text-muted"><i
                                                    class='bx bxl-slack-old secondary-text '></i> First name:
                                            </div>
                                            <div class="col-6  "> <b> {{ memberDtl.first_name }}</b></div>
                                            <div class="col-6 text-muted"><i
                                                    class='bx bxl-slack-old secondary-text'></i> Last name:
                                            </div>
                                            <div class="col-6  "> <b> {{ memberDtl.last_name }}</b></div>
                                            <div class="col-6 text-muted"><i class='bx bx-church secondary-text'></i>
                                                Church name:</div>
                                            <div class="col-6 "> <b> {{ memberDtl.church_name }}</b></div>
                                            <div class="col-6 text-muted"><i class='bx bx-map secondary-text'></i>
                                                Address:</div>
                                            <div class="col-6 "> <b> {{ memberDtl.address }}</b></div>
                                            <div class="col-6 text-muted"><i class='bx bx-time-five secondary-text'></i>
                                                Joined At:
                                            </div>
                                            <div class="col-6 "> <b> {{ memberDtl.joined }}</b></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <hr v-if="loanRepaymnt.length !== 0">
                    <b v-if="loanRepaymnt.length !== 0" class="secondary-text mb-3">Loan repayments</b>
                    <table v-if="loanRepaymnt.length !== 0" class="table table-sm table-responsive striped txt-1">
                        <tr class="primary">

                            <th>Amount</th>
                            <th>Date</th>
                        </tr>

                        <tbody>
                            <tr v-for="rep in loanRepaymnt">
                                <td>{{ rep.amount }}</td>
                                <td>{{ rep.date }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/axios';

export default {
    name: 'memberList',
    data() {
        return {
            donations: [],
            loanDtl: [],
            member_id: null,
            memberDtl: [],
            loanRepaymnt: []
        };
    },
    mounted() {
        this.fetchdonations()
        // console.log(' iiiiiiiiiiiiii')
    }
    ,


    methods: {
        async fetchdonations() {

            try {
                const response = await api.get('/dukorane/donations/');

                this.donations = response.data; // Assuming the response contains a token

                console.log(this.donations)


            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
        async getDonationDetail(id) {
            try {
                const response = await api.get('/dukorane/donations/' + id + '/');
                this.member_id = response.data['response_data']['loan']['member']
                const member = await api.get('/dukorane/members/' + this.member_id + '/');
                this.memberDtl = member.data['response_data']['member']


                this.loanDtl = response.data['response_data']['loan']
                this.loanRepaymnt = response.data['response_data']['repayment']
                console.log('Login:', this.loanDtl, this.memberDtl,
                    response);
            } catch (error) {

                // Handle login errors (e.g., display error message)
            }
        }
    },
};
</script>