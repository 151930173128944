<template>
    <div class="container">
        <div class="row justify-content-center">
            <h3 class="mt-3">Dashboard</h3>
            <hr>

            <!-- Existing Cards -->
            <div class="col-auto mt-4">
                <div class="card shadow text-light" style="width: 14rem; background: rgb(28,94,129);
                background: linear-gradient(90deg, rgba(28,94,129,1) 37%, rgba(0,0,0,1) 100%);">
                    <div class="card-body">
                        <h4 style="font-weight:800;" class="card-title">Members</h4>
                        <p class="card-text">{{ dash.total_members }}</p>
                        <a href="/members/list" class="button-secondary-round-full"> Details &nbsp;&nbsp;&nbsp;<i class='bx bx-right-top-arrow-circle'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-auto mt-4">
                <div class="card shadow text-light" style="width: 14rem; background: rgb(28,94,129);
                background: linear-gradient(90deg, rgba(28,94,129,1) 37%, rgba(66,20,7,1) 100%);">
                    <div class="card-body">
                        <h4 style="font-weight:800;" class="card-title">Given Loan</h4>
                        <p class="card-text"><span v-if="!dash.total_loans_issued">0</span> {{ dash.total_loans_issued }}</p>
                        <a href="/loans/list" class="button-secondary-round-full"> Details &nbsp;&nbsp;&nbsp;<i class='bx bx-right-top-arrow-circle'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-auto mt-4">
                <div class="card shadow text-light" style="width: 14rem; background: rgb(28,94,129);
                background: linear-gradient(90deg, rgba(28,94,129,1) 37%, rgba(66,7,65,1) 100%);">
                    <div class="card-body">
                        <h4 style="font-weight:800;" class="card-title">Left to Pay</h4>
                        <p class="card-text">{{ dash.total_money_in_unpaid_loans }}</p>
                        <a href="#" class="button-secondary-round-full"> Details &nbsp;&nbsp;&nbsp;<i class='bx bx-right-top-arrow-circle'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-auto mt-4">
                <div class="card shadow text-light" style="width: 14rem; background: rgb(28,94,129);
                background: linear-gradient(90deg, rgba(28,94,129,1) 37%, rgba(7,66,8,1) 100%);">
                    <div class="card-body">
                        <h4 style="font-weight:800;" class="card-title">Donations</h4>
                        <p class="card-text"><span v-if="!dash.total_donations">0</span> {{ dash.total_donations }}</p>
                        <a href="/donations/list" class="button-secondary-round-full"> Details &nbsp;&nbsp;&nbsp;<i class='bx bx-right-top-arrow-circle'></i></a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Recent Transactions Table -->
        <div v-if="false" ass="row justify-content-center mt-5">
            <h4>Recent Transactions</h4>
            <div class="custom-table-container mt-3">
                <table class="custom-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Message</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(transaction, index) in dash.recent_actions" :key="index">
                            <td>{{ transaction.action_type }}</td>
                            <td>{{ transaction.message }}</td>
                            <td>{{ new Date(transaction.timestamp).toLocaleString() }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/axios';

export default {
    name: 'home',
    data() {
        return {
            dash: {
                total_members: 0,
                total_loans_issued: 0,
                total_money_in_unpaid_loans: 0,
                total_donations: 0,
                recent_actions: [] // Updated variable name for transactions
            }
        };
    },

    mounted() {
        this.Dashboard();
    },
    methods: {
        async Dashboard() {
            try {
                const response = await api.get('/dukorane/dashboard/');
                this.dash = response.data['response_data'];
                this.$router.push('#');
            } catch (error) {
                console.error('Dashboard data fetching error:', error);
            }
        },
    },
};
</script>


<style scoped>


.custom-table-container {
    width: 100%;
    overflow-x: auto;
    padding: 15px;
    background-color: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    color: #333;
}

.custom-table th,
.custom-table td {
    padding: 12px 15px;
    text-align: left;
}

.custom-table th {
    background-color: #1c5e81;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #e9f1f5;
}

.custom-table tbody tr:hover {
    background-color: #d3e8f0;
}

.custom-table td {
    border-bottom: 1px solid #ddd;
}


</style>

