<template>
    <div class="container">
        <div class="row mt-3">
            <div class="col-2">
                <a class="button-primary" href="/loans/add">Give loan</a>
            </div>
            <div class="col text-center">
                <b class="secondary-text">LOANS LIST</b>
                <hr>
            </div>
            <div class="col-2"></div>
            <div class="col-12">
                <table class="table table-sm table-responsive txt-1">
                    <tr class="primary">
                        <th>Amount</th>
                        <th>Member</th>

                        <th>Loan duration</th>
                        <th>Repaid</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                    <tbody>
                        <tr v-for="loan in loans.response_data" :key="loan.id">
                            <td>{{ loan.amount }}</td>
                            <td>{{ loan.member_first_name }} {{ loan.member_last_name }}</td>
                            <td>{{ loan.loan_duration }} Year(s)</td>
                            <td><span v-if="loan.is_repaid">Yes</span><span v-else>No</span></td>
                            <td>{{ loan.date }}</td>
                            <td>
                                <button @click="getLoanDetail(loan.id)" type="button" class="button-primary"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <i class='bx bx-collapse-alt'></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail <b class="secondary-text">Loan</b></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <b class="secondary-text mb-3">Loan</b>
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Amount:</div>
                                        <div class="col-6"><b>{{ loanDtl.amount || 0 }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Total Amount:</div>
                                        <div class="col-6"><b>{{ total_amaount_to_be_paid || 0 }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Paid Amount:</div>
                                        <div class="col-6"><b>{{ total_amaount_to_be_paid - amount_left_to_be_paid || 0 }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Left to Pay:</div>
                                        <div class="col-6"><b>{{ amount_left_to_be_paid || 0 }}</b></div>
                                        
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Loan Duration:</div>
                                        <div class="col-6"><b>{{ loanDtl.loan_duration }} Year(s)</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Is Repaid:</div>
                                        <div class="col-6"><b v-if="loanDtl.is_repaid">Yes</b><b v-else>No</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Date:</div>
                                        <div class="col-6"><b>{{ loanDtl.date || 'N/A' }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-dialpad-alt secondary-text'></i> Description:</div>
                                        <div class="col-6"><b>{{ loanDtl.description || 'N/A' }}</b></div>
                                        
                                        <!-- New Pay Button -->



                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="container-fluid">
                                    <div v-if="memberDtl" class="row justify-content-center">
                                        <b class="secondary-text mb-3">Loan Owner</b>
                                        <div class="col-6 text-muted"><i class='bx bxl-slack-old secondary-text'></i> First Name:</div>
                                        <div class="col-6"><b>{{ loanDtl.member_first_name || 'N/A' }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bxl-slack-old secondary-text'></i> Last Name:</div>
                                        <div class="col-6"><b>{{ loanDtl.member_last_name || 'N/A' }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-church secondary-text'></i> Church Name:</div>
                                        <div class="col-6"><b>{{ loanDtl.member_church_name || 'N/A' }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-map secondary-text'></i> Address:</div>
                                        <div class="col-6"><b>{{ loanDtl.member_address || 'N/A' }}</b></div>
                                        <div class="col-6 text-muted"><i class='bx bx-time-five secondary-text'></i> Joined At:</div>
                                        <div class="col-6"><b>{{ loanDtl.member_joined || 'N/A' }}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr v-if="loanRepaymnt.length !== 0">
                    <b v-if="loanRepaymnt.length !== 0" class="secondary-text mb-3">Loan Repayments</b>
                    <table v-if="loanRepaymnt.length !== 0" class="table table-sm table-responsive striped txt-1">
                        <tr class="primary">
                            <th>Amount</th>
                            <th>Date</th>
                        </tr>
                        <tbody>
                            <tr v-for="rep in loanRepaymnt" :key="rep.id">
                                <td>{{ rep.amount }}</td>
                                <td>{{ rep.date }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="!loanDtl.is_repaid" class="col-12 my-3 text-center">
                                            <button 
                                                @click="showPaymentSection = true" 
                                                class="button-primary"
                                                :disabled="showPaymentSection"
                                            >
                                                Make Repayment
                                            </button>
                                        </div>
                                                                                <!-- Payment Section -->
                                                                                <div v-if="showPaymentSection" class="col-12 mt-3">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-6"   >
                                                    <div class="card p-3">
                                                <h6 class="secondary-text mb-3">Repayment Details</h6>
                                                <div class="form-group">
                                                    <label>Repayment Amount:</label>
                                                    <input 
                                                        type="number" 
                                                        v-model="paymentAmount" 
                                                        class="form-control" 
                                                        :class="{ 'is-invalid': paymentError }"
                                                        :disabled="isProcessing"
                                                    >
                                                    <div v-if="paymentError" class="invalid-feedback">
                                                        {{ paymentError }}
                                                    </div>
                                                </div>
                                                <div class="mt-3 d-flex justify-content-end gap-2">
                                                    <button 
                                                        @click="cancelPayment" 
                                                        class="btn btn-outline"
                                                        :disabled="isProcessing"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button 
                                                        @click="processPayment" 
                                                        class="button-primary"
                                                        :disabled="isProcessing || !isValidPayment"
                                                    >
                                                        <span v-if="isProcessing" class="spinner-border spinner-border-sm me-2"></span>
                                                        {{ isProcessing ? 'Processing...' : 'Pay' }}
                                                    </button>
                                                </div>
                                            </div>
                                                </div>

                                            </div>
                                        </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/axios';
// import { Alert } from '@/components/ui/alert';

export default {
    name: 'memberList',
    // components: {
    //     Alert
    // },
    data() {
        return {
            loans: {},
            loanLeftToPay:0,
            loanDtl: {},
            member_id: null,
            memberDtl: {},
            loanRepaymnt: [],
            showPaymentSection: false,
            paymentAmount: '',
            paymentError: '',
            isProcessing: false,
            amount_left_to_be_paid :0,
            total_amaount_to_be_paid:0

        };
    },
    computed: {
        isValidPayment() {
            const amount = Number(this.paymentAmount);
            return amount > 0 && amount <= this.getRemainingAmount;
        },
        getRemainingAmount() {
            const totalPaid = this.loanRepaymnt.reduce((sum, payment) => sum + Number(payment.amount), 0);
            return Number(this.total_amaount_to_be_paid) - totalPaid;
        }
    },
    watch: {
        paymentAmount(newValue) {
            this.validatePayment(newValue);
        }
    },
    mounted() {
        this.fetchLoans();

        const modal = document.getElementById('exampleModal');
        if (modal) {
            modal.addEventListener('hidden.bs.modal', this.handleModalClose);
        }
    },

    beforeUnmount() {
        const modal = document.getElementById('exampleModal');
        if (modal) {
            modal.removeEventListener('hidden.bs.modal', this.handleModalClose);
        }
    },

    methods: {
        async fetchLoans() {
            try {
                const response = await api.get('/dukorane/loans/');
                this.loans = response.data || {};
            } catch (error) {
                console.error('Error fetching loans:', error);
            }
        },
        async getLoanDetail(id) {
            try {
                const response = await api.get(`/dukorane/loans/${id}/`);
                
                if (response.data && response.data['response_data']) {
                    const loanData = response.data['response_data']['loan'];
                    const repaymentData = response.data['response_data']['repayment'];

                    if (loanData) {
                        this.loanDtl = loanData;
                        this.member_id = loanData.member;
                            console.log('countddddd: ', response.data['response_data'])
                            this.amount_left_to_be_paid = response.data['response_data'].amount_left_to_be_paid
                            this.total_amaount_to_be_paid = response.data['response_data'].total_amaount_to_be_paid

                    }

                    if (repaymentData) {
                        this.loanRepaymnt = repaymentData;
                    }

                    if (this.member_id) {
                        const memberResponse = await api.get(`/dukorane/members/${this.member_id}/`);
                        if (memberResponse.data && memberResponse.data['response_data']) {
                            this.memberDtl = memberResponse.data['response_data']['member'];


                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching loan details:', error);
            }
        },
        validatePayment(value) {
            const amount = Number(value);
            if (!value) {
                this.paymentError = 'Payment amount is required';
            } else if (isNaN(amount)) {
                this.paymentError = 'Please enter a valid number';
            } else if (amount <= 0) {
                this.paymentError = 'Payment amount must be greater than 0';
            } else if (amount > this.getRemainingAmount) {
                this.paymentError = `Payment cannot exceed the remaining amount: ${this.getRemainingAmount}`;
            } else {
                this.paymentError = '';
            }
        },
        cancelPayment() {
            this.showPaymentSection = false;
            this.paymentAmount = '';
            this.paymentError = '';
        },
        async processPayment() {
            if (!this.isValidPayment) return;

            this.isProcessing = true;
            try {
                await api.post(`/dukorane/loans/repayment/${this.loanDtl.id}/`, {
                    amount: Number(this.paymentAmount)
                });

                // Refresh loan details and repayments
                await this.getLoanDetail(this.loanDtl.id);
                await this.fetchLoans();

                this.cancelPayment();
                alert('Payment processed successfully!');
            } catch (error) {
                console.error('Error processing payment:', error);
                alert('Failed to process payment. Please try again.');
            } finally {
                this.isProcessing = false;
            }
        },

        handleModalClose() {
            this.showPaymentSection = false;
            this.paymentAmount = '';
            this.paymentError = '';
        },
    }
};
</script>

<style scoped>
.card {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}

.gap-2 {
    gap: 0.5rem;
}
</style>