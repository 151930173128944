<template>






    <div class="container" style="padding:10px;">
        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/membership/list">List membership fees </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    ADD A MEMBERSHIP FEES
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
        </div>
        <div class="row justify-content-center" style="width:100%;">

            <div class="col-10 p-5  shadow-lg">

                <div class="row justify-content-center">

                    <div class="col">
                        <div class="text-center"><!-- <img src="./../../public/img/dukorane.png" style="width: 100px;"
                                alt="">-->
                        </div>

                        <form @submit.prevent="handleSubmit" class="" role="form">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="member">Member:</label>
                                            <div class="col-sm-9">
                                                <select class="form-select" id="member" v-model="member">
                                                    <option v-for='member in members' :value="member.id">{{
                                                        member.first_name }} {{ member.last_name }}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="amount">Amount:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="number" id="amount"
                                                    v-model="amount" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="custom_period_start">Custom
                                                period start:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="date" id="custom_period_start"
                                                    v-model="custom_period_start" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="custom_period_end">Custom period
                                                end:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="date" id="custom_period_end"
                                                    v-model="custom_period_end" />

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div style="margin-top:3em; text-align:center;">
                                <button type="submit" value="SUBMIT" class="button-primary px-5">
                                    SAVE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>





<script>
import api from '@/api/axios';

export default {
    name: 'Member ship add',
    data() {
        return {
            members: [],
            member: '',
            amount: '',
            custom_period_start: '',
            custom_period_end: ''
        };
    },

    mounted() {
        this.fetchMembers()
    },
    methods: {
        async fetchMembers() {

            try {
                const response = await api.get('/dukorane/members/');

                this.members = response.data['response_data']; // Assuming the response contains a token
                // localStorage.setItem('authToken', token);
                console.log(this.members)
                // this.$router.push('/membership/list'); 
                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
        async handleSubmit() {
            const data = {

                'member': this.member,
                'amount': this.amount,
                'custom_period_start': this.custom_period_start,
                'custom_period_end': this.custom_period_end
                // Your data to be sent
            };
            try {

                const response = await api.post('/dukorane/membership-fees/create/', data, {
                    headers: {
                        'content-Type': 'application/json', // Set default content type
                        accept: 'application/json' // Set default content type
                        //   'Authorization':'Dukorane '
                    }
                });
                window.location.href='/membership/list'



            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },

    },
};
</script>
<!-- 

<script>
import axios from 'axios'; // Assuming you have Axios installed

export default {
    data() {
        return {

            custom_period_end: '',
            custom_period_start: '',
            address: '',
            joined: '',
            picture: null, // Store selected image file
        };
    },
    methods: {
        async handleSubmit() {


            const formData = {

                'custom_period_end': this.custom_period_end,
                'custom_period_start': this.custom_period_start,
            }



            try {
                const response = await axios.post('/dukorane/membership-fees/create/', formData);

                // Handle successful creation (e.g., display success message, redirect)
                console.log('Member created successfully:', response.data);
            } catch (error) {
                console.error('Error creating member:', error);
                // Handle creation errors (e.g., display error message)
            }
        },
    },
};
</script> -->