<template>






    <div class="container" style="padding:10px;">
        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/loans/list">Loans list </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    ADD A LOAN
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
        </div>
        <div class="row justify-content-center" style="width:100%;">

            <div class="col-10 p-5  shadow-lg">

                <div class="row justify-content-center">

                    <div class="col">
                        <div class="text-center"><!-- <img src="./../../public/img/dukorane.png" style="width: 100px;"
                                alt="">-->
                        </div>

                        <form @submit.prevent="handleSubmit" class="" role="form">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="member">Member:</label>
                                            <div class="col-sm-9">
                                                <select class="form-select" id="member" v-model="member">
                                                    <option v-for='member in members' :value="member.id">{{
                                                        member.first_name }} {{ member.last_name }}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="amount">Amount:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="number" id="amount"
                                                    v-model="amount" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="date">Date:</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="date" id="date" v-model="date" />

                                            </div>
                                        </div>

                                    </div>
                                </div> -->
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="loan_duration">Loan
                                                duration:</label>
                                            <div class="col-sm-9">

                                                <select name="" id="" class="form-select" v-model="loan_duration">
                                                    <option value="1">1 Year</option>
                                                    <option value="2">2 Years</option>
                                                </select>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="is_repaid">Repaid:</label>
                                            <div class="col-sm-9">

                                                <select name="" id="" class="form-select" v-model="is_repaid">
                                                    <option value="false">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>


                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <div class="row">
                                            <label class="col-sm-3 txt-1 my-auto" for="description">Description:</label>
                                            <div class="col-sm-9">
                                                <textarea name="" id="" class="form-control"  v-model="description"></textarea>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div style="margin-top:3em; text-align:center;">
                                <button type="submit" value="SUBMIT" class="button-primary px-5">
                                    SAVE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>





<script>
import api from '@/api/axios';

export default {
    name: 'loan add',
    data() {
        return {



            members: [],
            member: '',
            amount: '',
            // date: '',
            loan_duration: '',
            description: '',
            is_repaid: ''
        };
    },

    mounted() {
        this.fetchMembers()
    },
    methods: {
        async fetchMembers() {

            try {
                const response = await api.get('/dukorane/members/');

                this.members = response.data['response_data']; // Assuming the response contains a token
                // localStorage.setItem('authToken', token);
                console.log(this.members)

                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
        async handleSubmit() {
            const data = {
                'member': this.member,
                'amount': this.amount,
                // 'date': this.date,
                'loan_duration': this.loan_duration,
                'description': this.description,
                'is_repaid': this.is_repaid

            };
            try {

                const response = await api.post('/dukorane/loans/create/', data, {
                    headers: {
                        'content-Type': 'application/json', // Set default content type
                        accept: 'application/json' // Set default content type
                        //   'Authorization':'Dukorane '
                    }
                });
                this.$router.push('/loans/list'); 


            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
    },
};
</script>
