import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
// import MemberListView from '../../views/MemberViews/MemberList.vue'
// import MemberAddView from "@/views/MemberViews/MemberAdd.vue"
import LoansList from '@/views/LoansView/LoansList.vue'
import LoansAdd from '@/views/LoansView/LoansAdd.vue'
import DonnationList from '@/views/DonnationsView/DonnationList.vue'
import DonnationAdd from '@/views/DonnationsView/DonnationAdd.vue'
import ExpensesList from '@/views/ExpensesView/ExpensesList.vue'
import ExpensesAdd from '@/views/ExpensesView/ExpensesAdd.vue'
import TransportList from '@/views/TransportView/TransportList.vue'
import TransportAddView from '@/views/TransportView/TransportAddView.vue'
import LoansAdminFeesList from '@/views/LoansView/LoansAdminFeesList.vue'
import LoansAdminFeesAddView from '@/views/LoansView/LoansAdminFeesAddView.vue'
import MemberListView from '@/views/MemberViews/MemberListView.vue'
import MemberAddView from '@/views/MemberViews/MemberAddView.vue'
import MemberShipList from '@/views/MemberViews/MemberShipList.vue'
import MemberShipAdd from '@/views/MemberViews/MemberShipAdd.vue'
import ReportView from '@/views/ReportsView/ReportView.vue'





const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'catchAll',
			 meta: {
      requiresAuth: true
    },
			component: HomeView
		},
		{
			path: '/login',
			name: 'login',
			component: LoginView
		},
		{
			path: '/members/list',
			name: 'memberList',
			meta: {
      requiresAuth: true
    },
			component: MemberListView
		},
		{
			path: '/members/add',
			name: 'memberAdd',
			meta: {
      requiresAuth: true
    },
			component: MemberAddView
		}, {
			path: '/loans/list',
			name: 'loansList',
			meta: {
      requiresAuth: true
    },
			component: LoansList
		}, {
			path: '/loans/add',
			name: 'loansAdd',
			meta: {
      requiresAuth: true
    },
			component: LoansAdd
		}, {
			path: '/loans/administration/fees/list',
			name: 'LoansAdminFeesList',
			meta: {
      requiresAuth: true
    },
			component: LoansAdminFeesList
		},
		{
			path: '/loans/administration/fees/add',
			name: 'LoansAdminFeesAdd',
			meta: {
      requiresAuth: true
    },
			component: LoansAdminFeesAddView
		}, {
			path: '/donations/list',
			name: 'donationsList',
			meta: {
      requiresAuth: true
    },
			component: DonnationList
		}, {
			path: '/donations/add',
			name: 'donationsAdd',
			meta: {
      requiresAuth: true
    },
			component: DonnationAdd
		}, {
			path: '/expenses/add',
			name: 'expensesAdd',
			meta: {
      requiresAuth: true
    },
			component: ExpensesAdd
		}, {
			path: '/expenses/list',
			name: 'expensesList',
			meta: {
      requiresAuth: true
    },
			component: ExpensesList
		}, {
			path: '/transport/list',
			name: 'transportList',
			meta: {
      requiresAuth: true
    },
			component: TransportList
		},{
			path: '/transport/add',
			name: 'transportAdd',
			meta: {
      requiresAuth: true
    },
			component: TransportAddView
		}, {
			path: '/membership/list',
			name: 'membership',
			meta: {
      requiresAuth: true
    },
			component: MemberShipList
		}, {
			path: '/membership/add',
			name: 'membershipAdd',
			meta: {
      requiresAuth: true
    },
			component: MemberShipAdd
		},
		{
			path: '/report/',
			name: 'report',
			meta: {
      requiresAuth: true
    },
			component: ReportView
		}, {
			path: '/about',
			name: 'about',
			meta: {
      requiresAuth: true
    },
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import ('../views/AboutView.vue')
		}
	]
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuth') === 'true';
//   if (to.meta.requiresAuth && !isAuthenticated) {
//     next('/login');
//   } else {
//     next();
//   }

next()
});

export default router
