// src/api/axios.js
import axios from 'axios';

// Set the base URL for the API
const baseURL = import.meta.env.VUE_APP_API_URL || 'https://assets.dukorane.bi';

// Create an Axios instance with default configurations
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json', // Corrected header case
    'Accept': 'application/json'
  }
}); 

// Request interceptor to add the Authorization header
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Dukorane ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      switch (status) {
        case 401:
          console.warn('Unauthorized (401):', data);
          // Redirect to login page and set isAuth to false
          localStorage.setItem('isAuth', 'false');
          window.location.href = '/login';
          break;
        case 403:
          console.warn('Forbidden (403):', data);
          // Handle forbidden error
          break;
        case 404:
          console.warn('Not Found (404):', data);
          // Handle not found error
          break;
        case 500:
          console.error('Internal Server Error (500):', data);
          // Handle internal server error
          break;
        default:
          console.error('Unhandled error:', error);
          // Handle any other errors
      }
    } else if (error.request) {
      // Handle network errors
      console.error('Request error:', error.request);
    } else {
      // Handle other types of errors
      console.error('Other error:', error.message);
    }

    return Promise.reject(error); // Chain further error handling if needed
  }
);

export default api;

// Utility functions for token management
export function getToken() {
  return localStorage.getItem('authToken');
}

export function setToken(token) {
  localStorage.setItem('authToken', token);
}

export function removeToken() {
  localStorage.removeItem('authToken');
}
