<template>
    <div class="container">

        <div class="row mt-3">
            <div class="col-2">
                <a class=" button-primary " href="/transport/add">Add transport </a>

            </div>
            <div class="col  text-center">
                <b class="secondary-text">
                    TRANSPORT LIST
                </b>
                <hr>
            </div>
            <div class="col-2">

                <!--  -->
            </div>
            <!-- <hr> -->
            <div class="col-12">
                <!-- <div class="text-center " v-if="members.response_data.length===0"><i class='bx bxs-like bx-spin secondary-text'></i> Loading ...</div> -->
                <table class="table table-sm table-responsive striped txt-1">
                    <tr class="primary">



                        <th>Amount</th>
                       
                        <th>Description</th>
                       
                        <th>Date</th>
                       
                    </tr>
                    <!-- </thead>   -->
                    <tbody>

                       
                        <tr v-for="transport in transports">
                            <td>{{ transport.amount }}</td>
                            <td>{{ transport.description }}</td>
                          
                            <td>{{ transport.date }}</td>
                            <!-- <td>
                                <button @click="getMemberDetail(member.id)" type="button" class="button-primary"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <i class='bx bx-collapse-alt'></i>
                                </button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    <!-- Button trigger modal -->

    
</template>




<script>
import api from '@/api/axios';

export default {
    name: 'memberList',
    data() {
        return {
            transports: [],
            memberProfile: [],
            memberPicture: '',
            memberLoan: []
        };
    },
    mounted() {
        this.fetchtransports()
        // console.log(' iiiiiiiiiiiiii')
    }
    ,


    methods: {
        async fetchtransports() {

            try {
                const response = await api.get('/dukorane/dukorane-transport/');

                this.transports = response.data; // Assuming the response contains a token
                // localStorage.setItem('authToken', token);
                console.log(this.transports)

                // this.$router.push('/'); // Navigate to protected route
            } catch (error) {
                console.error('Login error:', error);
                // Handle login errors (e.g., display error message)
            }
        },
       
    },
};
</script>