<template>
  <div class="banner"></div>

  <div class="container-fluid" style="padding:10px;">
    <div class="text-center">
      <img src="./../../public/img/dukorane.png" style="width: 100px;" alt="">
    </div>
    <div class="row justify-content-center" style="width:100%;">
      <div class="col-7 p-5 shadow-lg" style="margin-top: 30px;">
        <div class="row">
          <div class="col-4 my-auto">
            <p class="text-white fs-4 text-center" style="font-weight: 800;">CONNECTEZ-VOUS</p>
          </div>
          <div class="col-8">
            <form @submit.prevent="handleSubmit" class="mt-3" role="form">
              <div class="form-group">
                <div class="row">
                  <label class="col-sm-3 txt-1 my-auto text-dark" for="UserName">Username:</label>
                  <div class="col-sm-9">
                    <input 
                      class="form-control" 
                      type="text" 
                      id="username" 
                      v-model="username" 
                      :disabled="isLoading" />
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <div class="row">
                  <label class="col-sm-3 txt-1 my-auto text-dark" for="Password">Password:</label>
                  <div class="col-sm-9">
                    <input 
                      class="form-control" 
                      type="password" 
                      id="password" 
                      v-model="password" 
                      :disabled="isLoading" />
                  </div>
                </div>
              </div>
              <div style="margin-top:3em; text-align:center;">
                <button 
                  type="submit" 
                  class="button-primary px-5" 
                  :disabled="isLoading">
                  <span v-if="isLoading">Logging in...</span>
                  <span v-else>LOG IN</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/axios';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      isLoading: false, // Add loading state
    };
  },
  created(){
    localStorage.setItem('isAuth', false);
  },
  mounted() {
    localStorage.removeItem('authToken');
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true; // Start loading

      const data = {
        username: this.username,
        password: this.password,
      };

      try {
        const response = await api.post('/api/token/', data);
        const token = response.data.access;
        localStorage.setItem('authToken', token);
        localStorage.setItem('isAuth', true);
        window.location.href = '/';
      } catch (error) {
        console.error('Login error:', error);
        alert('Login failed. Please check your username and password.');
      } finally {
        this.isLoading = false; // Stop loading after request
      }
    },
  },
};
</script>

<style scoped>
.button-primary {
  position: relative;
}
</style>
